import React, { useEffect, useState } from "react";
import CustomInput from "../../commons/CustomInput";
import { BasicButton, ContentContainer, PageContainer, PageContentContainer, PageHeader } from "../../commons/styles";
import { Navbar } from "../../commons/navbar";
import { branches, categoryOfExpense } from "../../utils/constants";
import { v4 as uuidv4 } from 'uuid';
import { deleteExpense, fetchExpenseByUUID, fetchLockingDate, saveExpense } from "../../utils/FirebaseUtil";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { validateExpense } from "./ExpenseUtility";

const EditExpense = () => {
    const navigate = useNavigate();
    const params = useParams();
    const {uuid} = params;
    const [expense, setExpense] = useState({});

    useEffect(() => {
        fetchExpenseDetail(); 
    }, [uuid]);

    const fetchExpenseDetail = async () => {
        const expenseDetail = await fetchExpenseByUUID(uuid);
        const lockingDate = await fetchLockingDate();
        if(moment(expenseDetail.date).isBefore(moment(lockingDate))){
            alert("Expense too old to edit!");
            navigate(-1);
            return;
        }
        setExpense(expenseDetail);
    }

    const handleSaveExpense = async () => {
        const expenseData = expense;

        if (!validateExpense(expenseData)) {
            alert("All fields are required!");
            return;
        }

        const lockingDate = await fetchLockingDate();
        if(moment(expenseData.date).isBefore(moment(lockingDate))){
            alert("Expense too old to add!");
            return;
        }
        const isSaved = await saveExpense(expenseData);
        if(isSaved){
            alert("Done!");
            navigate(-1);
        }else{
            alert("Error!");
        }
    }

    const handleDeleteExpense = async () => {
        const isDeleted = await deleteExpense(expense);
        if(isDeleted){
            alert("Deleted!");
            navigate(-1);
        }else{
            alert("Error!");
        }
    }

    return (
        <PageContainer>
            <Navbar />
            <PageContentContainer>
                <ContentContainer>
                    <PageHeader>Send Expenses</PageHeader>

                    <CustomInput label={"Select Branch"} type="dropdown" value={expense.branch} onChangeValue={(v) => {
                        setExpense({...expense, branch: v});
                    }} menuItems={branches}/>
                    <CustomInput label={"Category of Expense"} type="dropdown" value={expense.category} onChangeValue={(v) => {
                        setExpense({...expense, category: v});
                    }} menuItems={categoryOfExpense}/>
                    <CustomInput label={"Amount"} type="number" value={expense.amount} onChangeValue={(v) => {
                        setExpense({...expense, amount: v});
                    }} placeholder="₹"/>
                    <CustomInput label={"Date"} type="date" value={expense.date} onChangeValue={(v) => {
                        setExpense({...expense, date: v});
                    }}/>
                    <CustomInput label={"Description"} value={expense.desc} onChangeValue={(v) => {
                        setExpense({...expense, desc: v});
                    }} placeholder="Expense Description"/>
                    <BasicButton onClick={handleSaveExpense}>Update Expense</BasicButton>
                    <BasicButton onClick={handleDeleteExpense}>Delete Expense</BasicButton>

                </ContentContainer>
            </PageContentContainer>
        </PageContainer>
    );
}

export default EditExpense;