import styled from "styled-components";
import bg from "../assets/bg.png";
import bglight from "../assets/bglight.png";
import scpplogo from "../assets/scpplogo.png";

export const PageContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-image: url(${bglight});
    background-size: cover;
`;

export const NavbarContainer = styled.div`
    background-image: url(${bg});
    padding: 1rem;
    font-size: 2rem;
    background-position: bottom;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;

    @media (max-width: 600px) {
        font-size: 1.3rem;
    }

`;

export const PageContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ContentContainer = styled.div`
    width: 70%;
    padding: 2rem 1rem;

    @media (max-width: 600px) {
        width: 99%;
    }

`;

export const PageHeader = styled.div`
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const InputContainer = styled.div`
    background-color: white;
    border-radius: 6px;
    border: none;
    outline: none;
    padding: 0.8rem 1rem;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    flex: 1;
    width: 80%;
    max-width: 300px;

    
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;

    input{
        color: black;
        flex: 1;
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 1.1rem;
        width: 100%;
    }

`;

export const SearchButton = styled.button`
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    color: #251775;
`;

export const DetailsGrid2Col = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
    margin: 2rem 0;
    font-size: 1.1rem;

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }

`;

export const CardGrid3Col = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
    margin: 2rem 0;
    font-size: 1.1rem;

    @media (max-width: 600px) {
        grid-template-columns: 1fr 1fr;
    }

`;

export const BoldText = styled.span`
    font-weight: bold;
    @media (max-width: 600px) {
        margin-bottom: 1rem;
        border-bottom: 1px solid #7b7b7b;
    }
`;

export const ProfilePic = styled.img`
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 1rem;
    cursor: pointer;
`;

export const FeatureCard = styled.div`
    background-color: white;
    border-radius: 6px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.1rem;
    text-align: center;
    transition: all 0.5s ease;

    &:hover{
        transform: translateY(-6px);
    }

`;

export const FeatureCardImg = styled.img`
    width: 100%;
`;

export const FeatureCardIcon = styled.div`
    margin-bottom: 0.8rem;
    color: #427eff;
`;

export const TableHeader = styled.div`
    background-color: #427eff;
    color: white;
    padding: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 600px) {
        font-size: 0.95rem;
    }
    @media (max-width: 400px) {
        font-size: 0.8rem;
    }
`;

export const ClickableCell = styled.div`
    text-decoration: underline;
    cursor: pointer;
`;

export const TableCell = styled.div`
    padding: 0.6rem;
    background-color: #dfe9ff;
    padding: 0.6rem;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    text-align: center;
    border-bottom: 1px solid #b0adad;
    @media (max-width: 600px) {
        font-size: 0.9rem;
        font-weight: 600;
        padding: 0.4rem;
    }
    @media (max-width: 400px) {
        font-size: 0.7rem;
        font-weight: 500;
        padding: 0.1rem;
    }
`;

export const SpaceBetweenRow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const FlexibleSpaceBetweenRow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 850px) {
        flex-direction: column;
    }
`;

export const BasicButton = styled.button`
    margin: 1rem 0;
    max-width: 300px;
    padding: 0.6rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    border-radius: 6px;
    cursor: pointer;
    background-color: #507cff;
    color: white;
    font-weight: bold;
    font-size: 1.05rem;

    @media (max-width: 600px) {
        /* width: 100%; */
    }

`;

export const EditButton = styled.button`
    padding: 0.6rem 1rem;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    border-radius: 6px;
    cursor: pointer;
    background-color: #507cff;
    color: white;
    font-weight: bold;
    font-size: 1.05rem;
    margin-left: 1rem;
`;

export const DeleteButton = styled.button`
    padding: 0.6rem 1rem;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    border-radius: 6px;
    cursor: pointer;
    background-color: #ff5050;
    color: white;
    font-weight: bold;
    font-size: 1.05rem;
    margin-left: 1rem;
`;

export const PostCard = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 500px;
    background-color: white;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 1rem;
`;

export const PostCredit = styled.div`
    display: flex;
    align-items: center;
    padding: 1rem;
`;

export const PostAuthorImage = styled.div`
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    background-image: url(${scpplogo});
    background-size: contain;
    border: 1px solid red;
    margin-right: 1rem;
`;

export const PostAuthorContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const DeletePostBtn = styled.div`
    color: red;
    cursor: pointer;
`;

export const PostDate = styled.div`
    color: #505050;
    font-size: 0.9rem;
`;

export const PostImage = styled.img`
    width: 100%;
    object-fit: contain;
`;

export const PostMessage = styled.div`
    padding: 1rem;
`;

export const EmptyVerticalHeight = styled.div`
    height: 5rem;
`;

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
`;

export const HeightSpacer = styled.div`
    height: 2rem;
`;