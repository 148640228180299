import React, { useEffect, useState } from "react";
import CustomInput from "../../commons/CustomInput";
import {
    BasicButton,
    ContentContainer,
    PageContainer,
    PageContentContainer,
    PageHeader,
} from "../../commons/styles";
import { Navbar } from "../../commons/navbar";
import { branches, categoryOfExpense, modeOfPayment } from "../../utils/constants";
import { v4 as uuidv4 } from "uuid";
import {
    deleteExpense,
    deletePayment,
    fetchExpenseByUUID,
    fetchFeeByUUID,
    fetchLockingDate,
    saveExpense,
    updatePayment,
} from "../../utils/FirebaseUtil";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { validateFeeData } from "../StudentSearch/StudentUtility";

const EditFee = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { uuid } = params;
    const [fee, setFee] = useState({});

    useEffect(() => {
        fetchFeeDetail();
    }, [uuid]);

    const fetchFeeDetail = async () => {
        const feeDetail = await fetchFeeByUUID(uuid);
        const lockingDate = await fetchLockingDate();
        if(moment(feeDetail.date).isBefore(moment(lockingDate))){
            alert("Fee Receipt too old to edit!");
            navigate(-1);
            return;
        }
        setFee(feeDetail);
    };

    const handleUpdateFee = async () => {
        if(!validateFeeData(fee)){
            alert("All fields are required!");
            return;
        }
        const isPaymentUpdateDone = await updatePayment(fee);
        if(isPaymentUpdateDone){
            alert("Updated!");
            navigate(-1);
        }else{
            alert("Error!");
        }
    }

    const handleDeleteFee = async () => {
        const isPaymentDeleteDone = await deletePayment(fee);
        if(isPaymentDeleteDone){
            alert("Deleted!");
            navigate(-1);
        }else{
            alert("Error!");
        }
    }

    return (
        <PageContainer>
            <Navbar />
            <PageContentContainer>
                <ContentContainer>
                    <PageHeader>Edit Fee</PageHeader>

                    <CustomInput
                        label={"Receipt Number"}
                        type="number"
                        value={fee.receiptNumber}
                        onChangeValue={(v) => {
                            setFee({
                                ...fee,
                                receiptNumber: v,
                            });
                        }}
                    />
                    <CustomInput
                        label={"Date of Payment"}
                        type="date"
                        value={fee.date}
                        onChangeValue={(v) => {
                            setFee({ ...fee, date: v });
                        }}
                    />
                    <CustomInput
                        label={"Amount"}
                        type="number"
                        value={fee.money}
                        onChangeValue={(v) => {
                            setFee({ ...fee, money: v });
                        }}
                        placeholder="₹"
                    />
                    <CustomInput
                        label={"Mode of Payment"}
                        type="dropdown"
                        value={modeOfPayment[parseInt(fee.mopIndex)]}
                        onChangeValue={(v) => {
                            setFee({
                                ...fee,
                                mopIndex: `${modeOfPayment.indexOf(v)}`,
                            });
                        }}
                        menuItems={modeOfPayment}
                    />
                    <BasicButton onClick={handleUpdateFee}>
                        Update Fee
                    </BasicButton>
                    <BasicButton onClick={handleDeleteFee}>
                        Delete Fee
                    </BasicButton>
                </ContentContainer>
            </PageContentContainer>
        </PageContainer>
    );
};

export default EditFee;
